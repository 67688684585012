<template>
  <!-- Bell Icon -->
  <i v-if="!isRinging" class="fa-solid fa-bell" aria-hidden="true"></i>
  <!-- Bell Ring Icon -->
  <i v-if="isRinging" class="fa-solid fa-bell-ring fa-shake" aria-hidden="true"></i>
</template>

<script setup lang="ts">
  import { ref, onMounted, onUnmounted } from 'vue';

  // Define reactive state to control the bell ringing
  const isRinging = ref(true);

  // Interval reference for cleanup
  let intervalId: number | undefined;

  onMounted(() => {
    // Start the interval when the component is mounted
    intervalId = window.setInterval(() => {
      isRinging.value = !isRinging.value;
    }, 2000); // Toggle every 2 seconds
  });

  // Clean up the interval when the component unmounts
  onUnmounted(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  });
</script>