// ProfilePouchRepo.ts
import PouchDB from "@/repos/PouchDB";
import type { QsoType, LogbookType, PaginationResult } from "@/types";

import { v4 as uuidv4 } from "uuid";
import { QSO_ID_PREFIX } from "@/constants";
import { normalizeQso } from "@/utils/normalizeQso";
import type { QsoRepoInterface } from "@/interfaces";
interface QsoSelector {
  _id: { $gte: string; $lte: string };
  qsoDateTime: { $exists: boolean };
  $or?: Array<{ [key: string]: { $regex: RegExp } }>;
}

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function rankSearchResults(query: string, results: QsoType[]): QsoType[] {
  const rankedResults = results.map((doc) => {
    let score = 0;

    // Exact match
    if (doc.call.toLowerCase() === query.toLowerCase()) {
      score = 3;
    }
    // Prefix match
    else if (doc.call.toLowerCase().startsWith(query.toLowerCase())) {
      score = 2;
    }
    // Partial match
    else if (doc.call.toLowerCase().includes(query.toLowerCase())) {
      score = 1;
    }

    return { doc, score };
  });

  // Sort by score in descending order (highest score first)
  rankedResults.sort((a, b) => b.score - a.score);

  // Extract and return sorted documents
  return rankedResults.map((item) => item.doc);
}


const QsoPouchRepo: QsoRepoInterface = {
  async create(params: { attrs: Partial<QsoType>, logbook: LogbookType }): Promise<QsoType> {
    const qsoId = `${QSO_ID_PREFIX}:${params.logbook._id}:${uuidv4()}`;
    const createdAt = new Date().toISOString();
    const newQso = {
      _id: qsoId,
      createdAt,
      ...params.attrs,
    } as QsoType;
    const normalizedQso = normalizeQso(newQso);
    await PouchDB.upsert(qsoId, () => normalizedQso);
    return PouchDB.get(qsoId);
  },
  async update(qso: QsoType): Promise<QsoType> {
    const qsoId = qso._id;
    const newQso = {
      ...qso,
      updatedAt: new Date().toISOString(),
    } as QsoType;
    await PouchDB.upsert(qsoId, () => normalizeQso(newQso));
    return PouchDB.get(qsoId);
  },


  async findAllByLogbook(logbookId: string): Promise<QsoType[]> {
    const result = await PouchDB.allDocs({
      include_docs: true,
      startkey: `${QSO_ID_PREFIX}:${logbookId}:`,
      endkey: `${QSO_ID_PREFIX}:${logbookId}:\ufff0`,
    });
    return result.rows
      .map((row) => row.doc as unknown as QsoType)
      .sort((a, b) => b.createdAt!.localeCompare(a.createdAt!));
  },

  async findAllByCallsign(callsign: string): Promise<QsoType[]> {
    // Use the index and sort by qsoDateTime in descending order
    const result = await PouchDB.find({
      selector: {
        call: { $eq: callsign },
        qsoDateTime: { $exists: true }
      },
      sort: [{ qsoDateTime: 'desc' }]  // Sort by date in descending order
    });

    return result.docs as QsoType[];
  },

  async countAllByLogbook(logbookId: string): Promise<number> {
    const result = await PouchDB.allDocs({
      startkey: `${QSO_ID_PREFIX}:${logbookId}:`,
      endkey: `${QSO_ID_PREFIX}:${logbookId}:\ufff0`,
    });
    return result.rows.length;
  },

  // deleteAllByLogbook pulls all QSO docs associated with a logbookId then
  // deletes them by doing a bulk operation to set _deleted: true on each.
  async deleteAllByLogbook(logbookId: string): Promise<any> {
    const result = await PouchDB.allDocs({
      startkey: `${QSO_ID_PREFIX}:${logbookId}:`,
      endkey: `${QSO_ID_PREFIX}:${logbookId}:\ufff0`,
    });

    const docsToDelete = result.rows
      .map((row) => ({ _id: row.id, _rev: row.value?.rev, _deleted: true }))

    return PouchDB.bulkDocs(docsToDelete)
  },

  async findAll(): Promise<QsoType[]> {
    const result = await PouchDB.allDocs({
      include_docs: true,
      startkey: `${QSO_ID_PREFIX}:`,
      endkey: `${QSO_ID_PREFIX}:\ufff0`,
    });
    return result.rows
      .map((row) => row.doc as unknown as QsoType)
      .sort((a, b) => b.createdAt!.localeCompare(a.createdAt!));
  },

  async findOne(id: string): Promise<QsoType> {
    return PouchDB.get(id);
  },
  async delete(id: string): Promise<QsoType> {
    const doc = await PouchDB.get(id);
    await PouchDB.remove(doc);
    return Promise.resolve(doc as unknown as QsoType)
  },

  async countAll(): Promise<number> {
    const result = await PouchDB.allDocs({
      startkey: `${QSO_ID_PREFIX}:`,
      endkey: `${QSO_ID_PREFIX}:\ufff0`,
      include_docs: false,
    });
    return result.rows.length;
  },

  async getPageCount(limit: number): Promise<number> {
    const totalDocs = await this.countAll();
    return Math.ceil(totalDocs / limit);
  },
  async search(searchQuery: string): Promise<QsoType[]> {

    // Define the selector logic similar to fetchAllWithPaging
    const selector: QsoSelector = {
      _id: { $gte: `${QSO_ID_PREFIX}:`, $lte: `${QSO_ID_PREFIX}:\ufff0` },  // Filter by QSO prefix
      qsoDateTime: { $exists: true },  // Ensure qsoDateTime field exists
    };

    // If a search query is provided, add search conditions for specified fields
    if (searchQuery) {
      const escapedQuery = escapeRegExp(searchQuery);
      const regex = new RegExp(escapedQuery, 'i');
      selector.$or = [
        { call: { $regex: regex } },
        { potaRef: { $regex: regex } },
        { name: { $regex: regex } },
      ];
    }

    // Perform the search with the constructed selector and return the results
    const result = await PouchDB.find({
      selector,
      sort: [{ qsoDateTime: 'desc' }],  // Sort by qsoDateTime in descending order for consistency
    });

    return result.docs as QsoType[];
  },
  async searchOperators(callsign: string, limit?: number): Promise<QsoType[]> {
    const selector = {
      _id: { $gte: `${QSO_ID_PREFIX}:`, $lte: `${QSO_ID_PREFIX}:\ufff0` },
      qsoDateTime: { $exists: true },
      call: { $regex: new RegExp(escapeRegExp(callsign), 'i') }
    };

    const findOptions: any = { selector };

    // Add limit to findOptions if it's defined
    // if (limit) {
    //   findOptions.limit = limit;
    // }

    const result = await PouchDB.find(findOptions);
    const docs = result.docs as QsoType[];
    const rankedResults = rankSearchResults(callsign, docs);
    // Collect unique operators with the latest QSO by 'call'
    const uniqueOperators = new Map<string, QsoType>();

    rankedResults.forEach((doc) => {
      if (!doc.qsoDateTime) return;  // Skip entries without a valid date

      const existingDoc = uniqueOperators.get(doc.call);

      // Convert qsoDateTime to Date safely, skipping undefined values
      const docDate = new Date(doc.qsoDateTime ?? 0);
      const existingDocDate = existingDoc ? new Date(existingDoc.qsoDateTime ?? 0) : new Date(0);

      // Update the map with the latest QSO per call based on qsoDateTime
      if (!existingDoc || docDate > existingDocDate) {
        uniqueOperators.set(doc.call, doc);
      }
    });

    const ops = Array.from(uniqueOperators.values())
    return limit ? ops.slice(0, limit) : ops;
  },
  async fetchAllWithPaging(
    page: number,
    limit: number,
    searchQuery?: string
  ): Promise<PaginationResult<QsoType>> {

    // Step 1: Set the base selector for filtering QSOs by prefix
    const selector: QsoSelector = {
      _id: { $gte: `${QSO_ID_PREFIX}:`, $lte: `${QSO_ID_PREFIX}:\ufff0` },  // Filter by QSO prefix
      qsoDateTime: { $exists: true },  // Ensure qsoDateTime field exists
    };

    // Step 2: If a search term is provided, add search on fields like call or parkRef


    if (searchQuery) {
      const escapedQuery = escapeRegExp(searchQuery);
      const regex = new RegExp(escapedQuery, 'i');
      selector.$or = [
        { call: { $regex: regex } },
        { potaRef: { $regex: regex } },
        { name: { $regex: regex } },
      ];
    }

    // Step 3: Count total QSOs with the QSO_ID_PREFIX
    const totalRecords = await this.countAll()

    // Step 4: Calculate total pages
    const totalPages = Math.ceil(totalRecords / limit);

    // Ensure the current page is within bounds
    const currentPage = Math.max(1, Math.min(page, totalPages));

    // Step 5: Calculate how many documents to skip
    const skip = (currentPage - 1) * limit;

    // Step 6: Fetch the paginated documents for the current page with sorting by qsoDateTime
    const result = await PouchDB.find({
      selector,
      sort: [{ qsoDateTime: 'desc' }],  // Sort by qsoDateTime in descending order
      limit: limit,                    // Apply pagination limit
      skip: skip                       // Skip the number of records for pagination
    });

    const docs = result.docs as QsoType[];

    // Step 7: Determine if there are previous/next pages
    const hasNextPage = currentPage < totalPages;
    const hasPrevPage = currentPage > 1;

    // Step 8: Return paginated results with metadata
    return {
      docs,           // The QSO documents for the current page
      currentPage,    // The current page number
      totalPages,     // The total number of pages
      hasNextPage,    // Whether there's a next page
      hasPrevPage,    // Whether there's a previous page
      totalRecords,   // The total number of matching QSOs
    };
  }
};

export default QsoPouchRepo;
