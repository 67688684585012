// ProfilePouchRepo.ts
import PouchDB from "@/repos/PouchDB";
import type { ProfileInterface } from "@/interfaces";
import { v4 as uuidv4 } from "uuid";
import { PROFILE_ID_PREFIX } from "@/constants";
import { normalizeProfile } from "@/utils/formatters";

const create = async (attrs: Partial<ProfileInterface>): Promise<ProfileInterface> => {
  const profileId = `${PROFILE_ID_PREFIX}:${uuidv4()}`;
  const createdAt = new Date().toISOString();
  const newProfile = {
    _id: profileId,
    createdAt,
    ...attrs,
  } as ProfileInterface;

  await PouchDB.upsert(profileId, () => normalizeProfile(newProfile));
  return PouchDB.get(profileId);
};

const update = async (profile: ProfileInterface): Promise<ProfileInterface> => {
  const updatedAt = new Date().toISOString();
  profile.updatedAt = updatedAt;

  await PouchDB.upsert(profile._id, () => normalizeProfile(profile));
  return PouchDB.get(profile._id);
};


const findAll = async (): Promise<ProfileInterface[]> => {
  const result = await PouchDB.allDocs({
    include_docs: true,
    startkey: `${PROFILE_ID_PREFIX}:`,
    endkey: `${PROFILE_ID_PREFIX}:\ufff0`,
  });

  return result.rows
    .map((row) => row.doc as unknown as ProfileInterface)
    .sort((a, b) => b.createdAt!.localeCompare(a.createdAt!));
};

const findOne = async (id: string): Promise<ProfileInterface> => {
  return PouchDB.get(id);
}

const deleteOne = async (id: string): Promise<ProfileInterface> => {
  const doc = await PouchDB.get(id);
  await PouchDB.remove(doc);
  return Promise.resolve(doc as unknown as ProfileInterface)
}

export const ProfilePouchRepo = {
  create,
  findAll,
  findOne,
  update,
  deleteOne
};
