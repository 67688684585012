import type { QsoType, LogbookType, PaginationResult } from "@/types";

import QsoPouchRepo from "@/repos/QsoPouchRepo";
import type { QsoServiceInterface } from "@/interfaces";
import LogbookService from "./LogbookService";

const QsoService: QsoServiceInterface = {
  async create(params: { attrs: QsoType, logbook: LogbookType }): Promise<QsoType> {
    const qso = await QsoPouchRepo.create(params);
    await LogbookService.update(params.logbook); // Calls update with qsoCount update
    return qso;
  },
  async batchCreate(params: { attrs: QsoType, logbook: LogbookType }): Promise<QsoType[]> {
    const createdQsos = await createQsosForMultipleEntries(params);
    await LogbookService.update(params.logbook); // 
    return createdQsos;
  },
  async update(qso: QsoType): Promise<QsoType> {
    return QsoPouchRepo.update(qso);
  },
  async findAllByLogbook(logbookId: string): Promise<QsoType[]> {
    return QsoPouchRepo.findAllByLogbook(logbookId);
  },
  async findAllByCallsign(callsign: string): Promise<QsoType[]> {
    return QsoPouchRepo.findAllByCallsign(callsign);
  },
  async countAllByLogbook(logbookId: string): Promise<number> {
    return QsoPouchRepo.countAllByLogbook(logbookId);
  },
  async deleteAllByLogbook(logbookId: string): Promise<QsoType[]> {
    return QsoPouchRepo.deleteAllByLogbook(logbookId);
  },
  async findAll(): Promise<QsoType[]> {
    return QsoPouchRepo.findAll();
  },
  async findOne(id: string): Promise<QsoType> {
    return QsoPouchRepo.findOne(id);
  },
  async delete(id: string): Promise<QsoType> {
    const deleted = await QsoPouchRepo.delete(id);
    const logbookId = "LOGBOOK:" + id.split("QSO:")[1].split(":")[1];
    const logbook = await LogbookService.findOne(logbookId);
    await LogbookService.update(logbook); // Calls update with qsoCount update
    return deleted;
  },
  async fetchAllWithPaging(page: number, limit: number, searchQuery: string): Promise<PaginationResult<QsoType>> {
    return QsoPouchRepo.fetchAllWithPaging(page, limit, searchQuery);
  },
  async search(searchQuery: string): Promise<QsoType[]> {
    return QsoPouchRepo.search(searchQuery);
  },
  async searchOperators(callsign: string, limit?: number): Promise<QsoType[]> {
    return QsoPouchRepo.searchOperators(callsign, limit);
  }
};

export const createQsosForMultiplePotaRefs = async (params: { attrs: QsoType, logbook: LogbookType }): Promise<QsoType[]> => {
  const { attrs, logbook } = params;

  // Split the potaRef by comma, trim each value, and filter out empty strings
  const parks = attrs.potaRef!.split(',')
    .map(park => park.trim())
    .filter(park => park !== "");  // Remove empty values

  const createdQsos: QsoType[] = [];

  for (const park of parks) {
    // Create a new QSO for each valid park, modifying only the potaRef
    const qsoForPark = { ...attrs, potaRef: park };
    const createdQso = await QsoPouchRepo.create({ attrs: qsoForPark, logbook });
    createdQsos.push(createdQso);
  }

  return createdQsos;
};

export const createQsosForMultipleEntries = async (params: { attrs: QsoType, logbook: LogbookType }): Promise<QsoType[]> => {
  const { attrs, logbook } = params;

  // Extract and clean up callsigns
  const callsigns = attrs.call?.split(',')
    .map(sign => sign.trim())
    .filter(sign => sign) || [];

  // Extract and clean up parks if provided
  const parks = attrs.potaRef
    ? attrs.potaRef.split(',').map(ref => ref.trim()).filter(ref => ref)
    : [];

  const createdQsos: QsoType[] = [];

  if (parks.length > 0) {
    // Case 1: `potaRef` is provided, create a QSO for each combination of callsign and park
    for (const callsign of callsigns) {
      for (const park of parks) {
        const qsoForCombination = { ...attrs, potaRef: park, call: callsign };
        const createdQso = await QsoPouchRepo.create({ attrs: qsoForCombination, logbook });
        createdQsos.push(createdQso);
      }
    }
  } else {
    // Case 2: `potaRef` is not provided, create a QSO for each callsign only
    for (const callsign of callsigns) {
      const qsoForCallsign = { ...attrs, call: callsign };
      const createdQso = await QsoPouchRepo.create({ attrs: qsoForCallsign, logbook });
      createdQsos.push(createdQso);
    }
  }

  return createdQsos;
};

export default QsoService;
