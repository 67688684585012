import PouchDB from "@/repos/PouchDB";
import type { SyncType } from '@/types';
import { SyncPouchRepo } from '@/repos/SyncPouchRepo';
import HamrsDotAppService from '@/services/HamrsDotApp';

let syncHandler: null | ReturnType<typeof PouchDB.sync> = null;

const SyncService = {
  find(): Promise<SyncType> {
    return SyncPouchRepo.find();
  },
  update(sync: Partial<SyncType>): Promise<SyncType> {
    return SyncPouchRepo.update(sync);
  },

  // configureSync checks if the API key stored in sync is valid and
  // enables PouchDB replication.
  //
  // TODO(jlw) we need to do a real test though with two apps replicating to
  // the same place while offline
  async configureSync(
    sync: SyncType,
    complete: (success: boolean) => void,
  ) {
    if (sync?.hamrs.apiKey) {
      const url = await HamrsDotAppService.couchDbUrl(sync.hamrs.apiKey)
      if (syncHandler) {
        syncHandler.cancel()
      }
      if (url) {
        // Do a single 1-way pull from the remote db when we start up.
        await PouchDB.replicate.from(url);

        // Once that's done we start a live 2-way sync which will run in the
        // background and tells us about errors via our complete function.
        // Save the handler to so we can cancel it if they change the api key
        // again.
        syncHandler = PouchDB.sync(url, {live: true, retry: true});
        syncHandler.on('change', function () {
            // pouch: document changes detected
          }).on('paused', function () {
            // pouch: replication paused (e.g. replication up to date, user went offline)
            complete(true)
          }).on('active', function () {
            // pouch: replication active (e.g. new changes replicating, user went back online)
          }).on('denied', function () {
            // pouch: a document failed to replicate (e.g. due to permissions)
          }).on('error', function () {
            // pouch: handle error
            // TODO(jlw) if bad, toast an error?
          });
      }
    }
  },

  async cancelSyncAndDestroy() {
    return new Promise<void>((resolve, reject) => {
      if (!syncHandler) {
        reject(new Error('syncHandler is null'));
        return;
      }

      // Register a callback handler to wait for syncing to be complete.
      syncHandler.on('complete', async function () {
        // Data is done syncing, delete our local copy.
        await PouchDB.destroy()

        // We're done
        resolve();
      });

      // Tell pouch to stop syncing with our cloud db.
      syncHandler.cancel();
    }).catch((error) => {
      console.error('Error in cancelSyncAndDestroy:', error);
      throw error;
    });;
  },
};

export default SyncService;
