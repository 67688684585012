import type { LookupServiceResponseType, HamQthResponseType } from "@/types";

export default function HamQthResponseToLogbookResponseTransformer(
  response: HamQthResponseType
): LookupServiceResponseType {
  const lookup = response.HamQTH.search!;
  return {
    provider: "HAMQTH",
    callsign: lookup.callsign.toUpperCase(),
    fullName: lookup.adr_name.trim(),
    firstName: lookup.adr_name.trim().split(" ")[0],
    lastName: lookup.adr_name.trim().split(" ").slice(-1)[0],
    address1: lookup.adr_street1,
    address2: lookup.adr_street2 || "",
    city: lookup.adr_city || "",
    state: lookup.us_state || "",
    zip: lookup.adr_zip,
    country: lookup.country,
    gridsquare: lookup.grid,
    latitude: lookup.latitude,
    longitude: lookup.longitude,
    ituZone: lookup.itu,
    cqZone: lookup.cq || "",
    // The following fields are optional and not provided by HamDbResponseType
    // They will be initialized to undefined, not sure if I need this or not yet.
    county: lookup.us_county || "",
    class: undefined,
    continent: undefined,
    email: undefined,
  };
}


// <HamQTH xmlns="https://www.hamqth.com" version="2.8">
//   <search>
//     <callsign>ab5xs</callsign>
//     <nick>Jim Davis</nick>
//     <qth>Hurst, TX</qth>
//     <country>United States</country>
//     <adif>291</adif>
//     <itu>7</itu>
//     <cq>4</cq>
//     <grid>EM12</grid>
//     <adr_name>James P Davis</adr_name>
//     <adr_street1>208 Plainview Dr</adr_street1>
//     <adr_city>Hurst</adr_city>
//     <adr_zip>76054</adr_zip>
//     <adr_country>United States</adr_country>
//     <adr_adif>291</adr_adif>
//     <us_state>TX</us_state>
//     <us_county>Tarrant</us_county>
//     <lotw>?</lotw>
//     <qsldirect>?</qsldirect>
//     <qsl>?</qsl>
//     <eqsl>?</eqsl>
//     <email>AB5XS@yahoo.com</email>
//     <latitude>32.86</latitude>
//     <longitude>-97.18</longitude>
//     <continent>NA</continent>
//     <utc_offset>6</utc_offset>
//     <picture>
//       https://www.hamqth.com/userfiles/a/ab/ab5xs/_header/header.jpg?ver=1
//     </picture>
//   </search>
// </HamQTH>;



// <HamQTH xmlns="https://www.hamqth.com" version="2.8">
//   <search>
//     <callsign>OK2CQR</callsign>
//     <nick>Petr</nick>
//     <qth>Neratovice</qth>
//     <country>Czech Republic</country>
//     <adif>503</adif>
//     <itu>28</itu>
//     <cq>15</cq>
//     <grid>JO70GG</grid>
//     <adr_name>Petr Hlozek</adr_name>
//     <adr_street1>17. listopadu 1065</adr_street1>
//     <adr_city>Neratovice</adr_city>
//     <adr_zip>27711</adr_zip>
//     <adr_country>Czech Republic</adr_country>
//     <adr_adif>503</adr_adif>
//     <district>BME</district>
//     <lotw>Y</lotw>
//     <qsldirect>Y</qsldirect>
//     <qsl>Y</qsl>
//     <eqsl>Y</eqsl>
//     <email>petr@ok2cqr.com</email>
//     <skype>PetrHH</skype>
//     <birth_year>1982</birth_year>
//     <lic_year>1998</lic_year>
//     <web>http://www.ok2cqr.com</web>
//     <facebook>https://www.facebook.com/petr.hlozek</facebook>
//     <twitter>https://twitter.com/ok2cqr</twitter>
//     <linkedin>http://www.linkedin.com/pub/petr-hlo%C5%BEek/45/434/598</linkedin>
//     <latitude>50.25344610000001</latitude>
//     <longitude>14.515040999999997</longitude>
//     <continent>EU</continent>
//     <utc_offset>-1</utc_offset>
//     <picture>
//       https://www.hamqth.com/userfiles/o/ok/ok2cqr/_header/header.jpg?ver=12
//     </picture>
//   </search>
// </HamQTH>;
