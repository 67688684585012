// 1731167257789-populate-qso-count-on-logbooks.js
import QsoPouchRepo from '@/repos/QsoPouchRepo';
import LogbookPouchRepo from '@/repos/LogbookPouchRepo';

export default async function migrate() {
  const migrationVersion = 1731167257789;
  try {
    const logbooks = await LogbookPouchRepo.findAll();
    for (const logbook of logbooks) {
      try {
        const qsoCount = await QsoPouchRepo.countAllByLogbook(logbook._id);
        logbook.qsoCount = qsoCount;
        await LogbookPouchRepo.update(logbook);
      } catch (err) {
        console.error(`Failed to update logbook ${logbook._id}:`, err);
        throw err;
      }
    }
  } catch (err) {
    console.error(`Migration ${migrationVersion} failed:`, err);
    throw err;
  }
}