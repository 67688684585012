export const requireSync = import.meta.env.VITE_REQUIRE_SYNC?.toLowerCase() === 'true'
export const hamrsDotAppURL = import.meta.env.VITE_HAMRS_DOT_APP || 'https://hamrs.app';
export const haveLoadingPage = import.meta.env.VITE_HAVE_LOADING_PAGE?.toLowerCase() === 'true'
export const pwaEnabled = import.meta.env.VITE_PWA_ENABLED?.toLowerCase() === 'true'
export const LD_CLIENT_SIDE_ID: string = (() => {
  const clientId = import.meta.env.VITE_LD_CLIENT_SIDE_ID
  if (!clientId) {
    throw new Error('LaunchDarkly client ID (VITE_LD_CLIENT_SIDE_ID) is required but not configured')
  }
  return clientId
})()