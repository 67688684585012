<script setup lang="ts">
  import { type PropType } from 'vue';
  import { type SpotPayload } from '@/services/PotaService';

  const props = defineProps({
    spotPayload: {
      type: Object as PropType<SpotPayload>,
      required: true
    }
  });

</script>

<template>
  <div data-testid="toast">
    <div data-testid="toast__header" class="font-bold text-sm mb-1 flex">
      <span data-testid="toast__badge"
        class="inline-flex items-center text-green-600 rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-bold uppercase">
        POTA
      </span>
      <span data-testid="toast__title" class="ml-2 text-base font-mono">
        Spot Created
      </span>
    </div>
    <div data-testid="toast__body">
      <div class="font-semibold titlecase text-sm">{{ props.spotPayload.activator }} @ {{ props.spotPayload.reference }}
      </div>
      <div class="font-medium titlecase text-sm mt-1 font-mono">
        <i class="fa-solid fa-binoculars"></i> Spotted by {{ props.spotPayload.spotter }}
      </div>
    </div>
  </div>
</template>
