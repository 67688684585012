import type { LookupServiceProviderType } from '@/types';

function formatMessage(provider: LookupServiceProviderType, message: string) {
  return `🔎 [${provider}] ${message}`;
}
export class ProviderSessionExpiredError extends Error {
  constructor(provider: LookupServiceProviderType, message: string) {
    super(formatMessage(provider, message));
    this.name = "ProviderSessionExpiredError";
  }
}
export class ProviderAuthenticationError extends Error {
  constructor(provider: LookupServiceProviderType, message: string) {
    super(formatMessage(provider, message));
    this.name = "ProviderAuthenticationError";
  }
}
export class ProviderCallsignNotFoundError extends Error {
  constructor(provider: LookupServiceProviderType, message: string) {
    super(formatMessage(provider, message));
    this.name = "ProviderCallsignNotFoundError";
  }
}

export default class LookupServiceError extends Error {
  constructor(
    public service: LookupServiceProviderType,
    public code: string,
    public message: string
  ) {
    super(message);
  }
}
