// src/utils/normalizeQso.ts
import { type QsoType } from '@/types';
import { trimObjectValues } from './formatters'; // Adjust the path as needed

// Helper function to validate the qsoDate and timeOn
function isValidDateTime(qsoDate: string | undefined, timeOn: string | undefined): boolean {
  if (!qsoDate || !timeOn) {
    return false;
  }

  const dateTimeString = `${qsoDate}T${timeOn}Z`;
  const date = new Date(dateTimeString);

  return !Number.isNaN(date.getTime());
}

// Function to normalize the QSO object
export const normalizeQso = (qso: Partial<QsoType>): QsoType => {
  qso = trimObjectValues(qso);

  // Fields that should be uppercased
  const fieldsToUppercase: Array<keyof QsoType> = [
    "call",
    "operator",
    "myPotaRef",
    "potaRef",
    "mode",
  ];

  // Fields that should be lowercased
  const fieldsToLowercase: Array<keyof QsoType> = [
    "band",
  ];

  const normalizedQso: Record<string, any> = {
    ...qso,
    ...(isValidDateTime(qso.qsoDate, qso.timeOn) && {
      qsoDateTime: new Date(`${qso.qsoDate}T${qso.timeOn}Z`).toISOString(),
    }),
  };

  // Uppercase relevant fields
  fieldsToUppercase.forEach((field) => {
    const fieldValue = qso[field];
    if (typeof fieldValue === "string") {
      normalizedQso[field] = fieldValue.toUpperCase();
    }
  });

  // Lowercase relevant fields
  fieldsToLowercase.forEach((field) => {
    const fieldValue = qso[field];
    if (typeof fieldValue === "string") {
      normalizedQso[field] = fieldValue.toLowerCase();
    }
  });

  return normalizedQso as QsoType;
};