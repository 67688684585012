import PouchDB from "pouchdb";
import PouchDBUpsert from "pouchdb-upsert";
import PouchDBFind from "pouchdb-find";

// Add PouchDB plugins
PouchDB.plugin(PouchDBUpsert);
PouchDB.plugin(PouchDBFind);

// Create PouchDB instance
const db = new PouchDB("hamrs.db");

// Function to get the migration version
export async function getMigrationVersion(): Promise<number> {
  const metaDocId = "MIGRATION_META";
  try {
    const meta = await db.get<{ _id: string; version: number }>(metaDocId);
    return meta.version || 0;
  } catch (err: any) {
    if (err.status === 404) {
      // Document doesn't exist, so version is 0
      return 0;
    } else {
      // Re-throw any other error
      throw err;
    }
  }
}

// Function to set the migration version
export async function setMigrationVersion(version: number): Promise<void> {
  const metaDocId = "MIGRATION_META";
  try {
    const meta = await db.get<{ _id: string; _rev: string; version: number }>(metaDocId);
    meta.version = version;
    await db.put(meta);
  } catch (err: any) {
    if (err.status === 404) {
      // Document doesn't exist, create a new one
      const meta = { _id: metaDocId, version };
      await db.put(meta);
    } else {
      // Re-throw any other error
      throw err;
    }
  }
}

// Function to delete custom indexes
export async function deleteCustomIndexes() {
  try {
    const indexes = await db.getIndexes();

    // Filter out the default "_all_docs" index
    const customIndexes = indexes.indexes.filter((index) => index.ddoc !== null);

    // Delete each custom index by its design document
    for (const index of customIndexes) {
      const ddoc = index.ddoc;

      if (ddoc) {
        const designDoc = await db.get(ddoc);
        await db.remove(designDoc);
        console.log(`Deleted index: ${index.name} (Design Doc: ${ddoc})`);
      }
    }

    console.log("All custom indexes deleted.");
  } catch (err) {
    console.error("Error deleting indexes:", err);
  }
}

// Ensure the index is created asynchronously
export async function ensureIndexes() {
  console.log("Ensuring indexes...");
  await db.createIndex({
    index: { fields: ["qsoDateTime"] },
  });
  await db.createIndex({
    index: { fields: ["title"] },
  });
  await db.createIndex({
    index: { fields: ["call", "qsoDateTime"] },
  });
}

export default db;