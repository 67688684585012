import type { ProfileInterface } from "@/interfaces";
export function newProfile(): ProfileInterface {
  const defaultProfile: ProfileInterface = {
    _id: "",
    language: "en",
    lookupService: 'HAMDB',
    operator: {
      callsign: '',
      firstName: '',
      lastName: '',
    },
    qrz: {
      username: '',
      password: '',
      apiKey: '',
      isSet: false,
      isValid: false

    },
    hamQth: {
      username: '',
      password: '',
      isSet: false,
      isValid: false
    }
  };
  return defaultProfile;
}

export default {
  newProfile,
};
