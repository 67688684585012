import ParksOnTheAirSchema from "@/formkitSchemas/ParksOnTheAirSchema";
import type { StickyFieldsType } from "@/types/StickyFieldsType";

const ParksOnTheAirForm = {
  schema: ParksOnTheAirSchema,
  stickyFields: [
    "freq",
    "band",
    "myPotaRef",
    "myGridsquare",
    "mode",
    "txPwr",
    "myCnty",
    "myState",
    "operator",
    "stationCallsign",
  ] as StickyFieldsType,
};

export default ParksOnTheAirForm;
