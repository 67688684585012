import type { HamDbResponseType, LookupServiceResponseType } from "@/types";

import { toTitleCase } from "@/utils/formatters";

export default function HamDbResponseToLogbookResponseTransformer(response: HamDbResponseType ): LookupServiceResponseType {
	const lookup = response.hamdb.callsign;
	return {
    provider: "HAMDB",
    callsign: lookup.call,
    class: lookup.class,
    fullName: `${lookup.fname} ${lookup.mi} ${lookup.name}`.trim(),
    firstName: lookup.fname,
    lastName: lookup.name,
    address1: lookup.addr1,
    address2: lookup.addr2,
    state: lookup.state,
    zip: lookup.zip,
    country: lookup.country,
    gridsquare: lookup.grid,
    latitude: lookup.lat,
    longitude: lookup.lon,
    // The following fields are optional and not provided by HamDbResponseType
    // They will be initialized to undefined, not sure if I need this or not yet.
    county: undefined,
    city: toTitleCase(lookup.addr2),
    ituZone: undefined,
    cqZone: undefined,
    continent: undefined,
    email: undefined,
  };
};


// Samples of the data returned from HamDB:
// {
//    "hamdb":{
//       "version":"1",
//       "callsign":{
//          "call":"KB0ICT",
//          "class":"G",
//          "expires":"09/05/2030",
//          "status":"A",
//          "grid":"EM17jq",
//          "lat":"37.6668349",
//          "lon":"-97.1841941",
//          "fname":"Jarrett",
//          "mi":"D",
//          "name":"Green",
//          "suffix":"",
//          "addr1":"1443 S SIERRA HILLS ST",
//          "addr2":"WICHITA",
//          "state":"KS",
//          "zip":"67230-7719",
//          "country":"United States"
//       },
//       "messages":{
//          "status":"OK"
//       }
//    }
// }
