import type { QsoType, LookupServiceResponseType } from "@/types";

export default function LookupServiceResponseToQsoTransformer(response: LookupServiceResponseType ): Partial<QsoType> {
	return {
		lookedUp: true,
		lookupSuccess: true,
		lookupService: response.provider,
		name: response.fullName,
		gridsquare: response.gridsquare,
		state: response.state,
		qth: response.city,
		country: response.country,
		cnty: response.county
	};
}
