import type { DefaultConfigOptions } from "@formkit/vue";
import { createProPlugin, datepicker, mask } from '@formkit/pro'
import { generateClasses } from "@formkit/themes";

const proPlugin = createProPlugin(import.meta.env.VITE_FORMKIT_PRO_KEY, {
  mask, datepicker
})

import formkitTheme from "./formkit.theme";

const config: DefaultConfigOptions = {
  plugins: [proPlugin],
  config: {

    classes: generateClasses(formkitTheme),
  },
};

export default config;
