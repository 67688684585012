export default [
  {
    $cmp: "Row",
    props: {
      columns: 12,
    },
    children: [
      {
        $cmp: "Column",
        props: {
          span: 8,
        },
        children: [
          {
            $cmp: "Container",
            children: [
              {
                $cmp: "Row",
                props: {
                  columns: 4,
                },
                children: [
                  {
                    $cmp: "TheirCall",
                    props: {
                      class: "col-span-2",
                    },
                  },
                  { $cmp: "RstSent" },
                  { $cmp: "RstReceived" },
                ],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 4,
                },
                children: [
                  { $cmp: "Time" },
                  { $cmp: "Date" },
                  {
                    $cmp: "Column",
                    props: {
                      span: 2,
                    },
                    children: [
                      {
                        $cmp: "Notes",
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [
                  {
                    $cmp: "Row",
                    props: {
                      columns: 2,
                    },
                    children: [
                      {
                        $cmp: "TheirName",
                      },
                      {
                        $cmp: "TheirCity",
                      },
                    ],
                  },
                  {
                    $cmp: "Row",
                    props: {
                      columns: 3,
                    },
                    children: [
                      {
                        $cmp: "TheirState",
                      },
                      {
                        $cmp: "TextField",
                        props: {
                          label: "County",
                          field: "cnty",
                        },
                      },
                      {
                        $cmp: "TheirGrid",
                        props: {
                          label: "Grid",
                        }
                      },
                    ],
                  },
                ],
              },
              {
                $el: "div",
                attrs: {
                  class: "flex justify-end mt-6",
                },
                children: [{ $cmp: "ClearButton" }, { $cmp: "SaveButton" }],
              },
            ],
          },
        ],
      },
      {
        $cmp: "Column",
        props: {
          span: 4,
        },
        children: [
          {
            $cmp: "Container",
            children: [
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [{ $cmp: "Frequency" }, { $cmp: "BandSelect" }],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [{ $cmp: "TxPower" }, { $cmp: "ModeSelect" }],
              },
              {
                $cmp: "Row",
                children: [{ $cmp: "MyCall", props: { columns: 2 } }],
              },
              {
                $cmp: "Row",
                children: [{ $cmp: "MyGrid", props: { columns: 2 } }],
              },
            ],
          },
        ],
      },
    ],
  },
];
