import { hamrsDotAppURL } from '@/config';

const HamrsDotAppService = {
  async couchDbUrl(key: string): Promise<string> {
    const response = await fetch(
      `${hamrsDotAppURL}/api/v1/couchdb_url`,
      {headers: {'Authorization': `Token ${key}`}}
    )
    const body = await response.json()

    return body.url
  }
}

export default HamrsDotAppService
