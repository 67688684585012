<script setup lang="ts">
  import { LOOKUP_SERVICES } from '@/constants';
  import type { LookupServiceProviderType } from '@/types';
  import { type PropType } from 'vue';
  const props = defineProps({
    callsign: {
      type: String,
      required: true,
    },
    provider: {
      type: String as PropType<LookupServiceProviderType>,
      required: true,
    },
  });
</script>

<template>
  <div data-testid="toast__callsign-not-found">
    <div class="font-bold text-sm mb-1" data-testid="toast__header">
      <span class="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-bold uppercase"
        style="color: #ff5252" data-testid="toast__badge">
        {{ LOOKUP_SERVICES[props.provider] }}
      </span>
      <span class="uppercase ml-2" data-testid="toast__title">Call not found</span>
    </div>
    <div data-testid="toast__body">
      No callsign found for <span class=" font-mono uppercase font-medium">{{ props.callsign }}</span>.
    </div>
  </div>
</template>
