<script setup lang="ts">
  import { type PropType } from 'vue';
  import { LOOKUP_SERVICES } from '@/constants';
  import type { LookupServiceResponseType } from '@/types/';

  const props = defineProps({
    lookup: {
      type: Object as PropType<LookupServiceResponseType>,
      required: true
    }
  });
</script>

<template>
  <div data-testid="toast__callsign-found">
    <div data-testid="toast__header" class="font-bold text-sm mb-1 flex">
      <span data-testid="toast__badge"
        class="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-bold uppercase"
        style="color: #2196f3">
        {{ LOOKUP_SERVICES[props.lookup.provider] }}
      </span>
      <span data-testid="toast__title" class="ml-2 text-base font-mono">
        {{ props.lookup.callsign }}
      </span>
    </div>
    <div data-testid="toast__body">
      <div class="font-semibold titlecase text-sm">{{ props.lookup.fullName }} </div>
      <div class="font-medium titlecase text-sm">{{ props.lookup.city }}, {{ props.lookup.state }} {{
        props.lookup.country }} </div>
    </div>
  </div>
</template>
