<script setup lang="ts">
  import type { QsoType } from '@/types';
  import { formatTimeAgo } from '@vueuse/core'
  import { type PropType, computed } from 'vue';
  const props = defineProps({
    qso: {
      type: Object as PropType<QsoType>,
      required: true,
    },
    duplicateQso: {
      type: Object as PropType<QsoType>,
      required: true,
    },
  });
  const timeAgo = computed(() => {
    let dateTime = new Date(`${props.qso.qsoDate}T${props.qso.timeOn}Z`)
    return formatTimeAgo(new Date(dateTime))
  })

</script>

<template>
  <div>
    <div class="font-bold text-sm mb-1">
      <span class="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-bold uppercase"
        style="color: #ff5252">DUPE</span>
      <span class="uppercase ml-2">Possible Duplicate</span>
    </div>
    <div>
      <div class="font-mono uppercase font-medium">{{ props.qso.call }}</div>
      <span class="text-sm block"> {{ props.qso.freq }}MHz - {{ props.qso.band }} - {{ props.qso.mode }}</span>
      <span class="text-sm"> {{ timeAgo }}</span>
    </div>
  </div>
</template>
