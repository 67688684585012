<script setup lang="ts">
  import { LOOKUP_SERVICES } from '@/constants';
  import type { LookupServiceProviderType } from '@/types';
  import { type PropType } from 'vue';
  const props = defineProps({
    provider: {
      type: String as PropType<LookupServiceProviderType>,
      required: true,
    },
    error: {
      type: String,
      required: false,
    },
  });
</script>

<template>
  <div>
    <div class="font-bold text-sm mb-1">
      <span class="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-bold uppercase"
        style="color: #ff5252">{{ LOOKUP_SERVICES[props.provider] }}</span>
      <span class="uppercase ml-2">Error</span>
    </div>
    <div>
      {{ error }}
    </div>
  </div>
</template>
