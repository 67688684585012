// src/plugins/sentry-setup.ts
import * as Sentry from "@sentry/vue";

export function initializeSentry(app: any, router: any, appVersion: string) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `hamrs@${appVersion}`, // Set the release version for Sentry
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        unmask: [".sentry-unmask"],
        networkDetailAllowUrls: [window.location.origin, 'api.hamdb.org', 'api.hamrs.app', 'xmldata.qrz.com', 'hamqth.com'],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", "https://logger.hamrs.app"],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}