import GenericSchema from "@/formkitSchemas/GenericSchema"
import type { StickyFieldsType } from "@/types/StickyFieldsType";

const GenericForm = {
  schema: GenericSchema,
  stickyFields: [
    "freq",
    "band",
    "myPotaRef",
    "myGridsquare",
    "mode",
    "txPwr",
    "myCnty",
    "myState",
    "operator",
    "stationCallsign",
  ] as StickyFieldsType,
};

export default GenericForm
