// store/profiles.ts
import { defineStore } from "pinia";
import ProfileService from "@/services/ProfileService";
import { useSettingStore } from "./SettingStore"
import { useLoggerStore } from "./LoggerStore"
import type { ProfileInterface } from "@/interfaces";
import { profilesLogger } from "@/utils/loggers";
import ProfileFactory from "@/factories/ProfileFactory";
import * as Sentry from "@sentry/vue";

export const useProfileStore = defineStore("profiles", {
  state: () => ({
    firstTimeSetup: false,
    profiles: [] as ProfileInterface[],
    activeProfile: undefined as ProfileInterface | undefined,
    editingProfile: undefined as ProfileInterface | undefined,
  }),
  actions: {
    async fetchProfiles() {
      profilesLogger("Fetching profiles...")
      try {
        const profiles = await ProfileService.findAll();
        this.profiles = profiles;
        profilesLogger(`Profiles fetched: ${profiles.length}`);
        this.firstTimeSetup = this.profiles.length === 0;
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    },
    async createProfile() {
      if (!this.editingProfile) { return; }
      try {
        const createdProfile = await ProfileService.create(this.editingProfile);
        profilesLogger('Created Profile:', createdProfile.operator.callsign);
        // Option 1: Refetch profiles after creation
        await this.fetchProfiles()
        this.editingProfile = undefined;
        return createdProfile;
      } catch (error) {
        console.error("Error creating profile:", error);
        throw error; // Re-throw to allow caller to handle
      }
    },
    async activateProfile(profile: ProfileInterface) {
      const activeProfile = this.profiles.find((prof) => prof._id === profile._id)
      if (activeProfile) {
        this.activeProfile = activeProfile;
        profilesLogger("Activating Profile:", activeProfile.operator.callsign);
        useSettingStore().setLookupProvider(activeProfile.lookupService);
        useSettingStore().setActiveProfileID(activeProfile._id);
        useLoggerStore().setOperator(activeProfile.operator.callsign);
        Sentry.setUser({ username: activeProfile.operator.callsign });
      }
    },
    async initNewProfile() {
      profilesLogger('Initializing New Profile');
      this.editingProfile = ProfileFactory.newProfile();
    },
    async updateProfile() {
      if (!this.editingProfile) { return; }
      try {
        const updatedProfile = await ProfileService.update(this.editingProfile);
        profilesLogger("Updated Profile:", updatedProfile.operator.callsign);
        await this.fetchProfiles();

        // If they edit the active profile then we activate the profile again
        // in case they've changed something about their callsign lookup
        // service.
        if (this.activeProfile?._id === updatedProfile._id) {
          this.activateProfile(updatedProfile);
        }
        this.editingProfile = undefined;
        return updatedProfile;
      } catch (error) {
        console.error("Error updating profile", error);
        throw error;
      }
    },
    async deleteProfile(profileId: string) {
      try {
        const deletedProfile = await ProfileService.delete(profileId);
        profilesLogger("Deleted Profile:", deletedProfile.operator.callsign);
        await this.fetchProfiles();
        return deletedProfile;
      } catch (error) {
        console.error("Error deleting profile", error);
        throw error;
      }
    }
  },
  getters: {
    findOne: (state) => {
      return (profileId: string) => state.profiles.find((profile) => profile._id === profileId)
    }
  }
});
