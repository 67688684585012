import { useToast, type PluginOptions } from "vue-toastification";

import type {
  LookupServiceProviderType,
  LookupServiceResponseType,
  PotaParkType,
  QsoType
} from "@/types";

import CallsignFoundToast from "@/components/toasts/CallsignFoundToast.vue";
import CallsignNotFoundToast from "@/components/toasts/CallsignNotFoundToast.vue";
import PotaParkFoundToastVue from "@/components/toasts/PotaParkFoundToast.vue";
import PotaSpotCreatedToastVue from "@/components/toasts/PotaSpotCreatedToast.vue";
import DuplicateQsoToast from "@/components/toasts/DuplicateQsoToast.vue";
import LookupProviderErrorToast from "@/components/toasts/LookupProviderErrorToast.vue";
import type { SpotPayload } from "@/services/PotaService";

const toast = useToast();

const baseOptions: PluginOptions = {
  timeout: 8500,
  icon: false,
}

export function useToastSystem() {
  function potaParkFoundToast(park: PotaParkType) {
    const content = {
      component: PotaParkFoundToastVue,
      props: {
        park: park,
      },
    };
    toast.success(content, baseOptions);
  }

  function potaSpotCreatedToast(spotPayload: SpotPayload) {
    const content = {
      component: PotaSpotCreatedToastVue,
      props: {
        spotPayload: spotPayload,
      },
    };
    toast.success(content, baseOptions);
  }

  function callsignFoundToast(
    lookup: LookupServiceResponseType,
  ) {
    const content = {
      component: CallsignFoundToast,
      props: {
        lookup: lookup,
      },
    };
    toast.info(content, baseOptions);
  }

  function callsignNotFoundToast(callsign: string, provider: LookupServiceProviderType) {
    const content = {
      component: CallsignNotFoundToast,
      props: {
        callsign: callsign,
        provider: provider,
      },
    };
    toast.error(content, baseOptions);
  }
  function lookupProviderErrorToast(provider: LookupServiceProviderType, error?: String) {
    const content = {
      component: LookupProviderErrorToast,
      props: {
        provider: provider,
        error: error,
      },
    };
    toast.error(content, baseOptions);
  }

  function duplicateQsoToast(qso: QsoType, duplicateQso: QsoType) {
    const content = {
      component: DuplicateQsoToast,
      props: {
        qso,
        duplicateQso,
      },
    };
    toast.error(content, baseOptions);
  }

  return {
    callsignFoundToast,
    callsignNotFoundToast,
    duplicateQsoToast,
    lookupProviderErrorToast,
    potaParkFoundToast,
    potaSpotCreatedToast

  }
}


