import { watch } from "vue";
import { useOnline } from "@vueuse/core";
import { useSettingStore } from "@/stores/SettingStore";

export function useNetworkStatus() {
  const settingStore = useSettingStore();
  const isOnline = useOnline();

  watch(
    isOnline,
    (newValue) => {
      settingStore.isOnline = newValue;
    },
    { immediate: true }
  );

  // Optionally return something, e.g., current online status
  return { isOnline };
}
