// main.ts

import { createApp } from "vue";
import { plugin as formkitPlugin, defaultConfig } from "@formkit/vue";
import Toast from "vue-toastification";
import { createPinia } from "pinia";
import FloatingVue from 'floating-vue';
import { LDPlugin } from 'launchdarkly-vue-client-sdk';

import 'floating-vue/dist/style.css';
import "./style.css";
import App from "./App.vue";
import router from "./router/router";
import formkitConfig from "./formkit.config";
import { initializeSentry } from "@/plugins/sentryInitializer";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { hamrsClientLogger } from "@/utils/loggers";
import { useSettingStore } from "@/stores/SettingStore";
import { pwaEnabled, LD_CLIENT_SIDE_ID } from "./config";
import "leaflet/dist/leaflet.css";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";
import "@fontsource/roboto-mono/300.css";
import "@fontsource/roboto-mono/400.css";
import "@fontsource/roboto-mono/500.css";
import "@fontsource/roboto-mono/600.css";
import "@fontsource/roboto-mono/700.css";
import "vue-toastification/dist/index.css";
import './cypressServiceInjector';
import { APP_VERSION } from './constants';

// Import the runMigrations function
import { runMigrations } from '@/migrations/migrationRunner';

const ENVIRONMENT = import.meta.env.MODE;

hamrsClientLogger("Version:", APP_VERSION);
hamrsClientLogger("Environment:", ENVIRONMENT);
hamrsClientLogger("PWA Enabled:", pwaEnabled);

async function bootstrap() {
  try {
    // Run migrations before initializing the app
    await runMigrations();

    // Create the app instance
    const app = createApp(App);
    const pinia = createPinia();

    app.use(pinia);

    // Now that Pinia is active, we can define toastOptions
    const toastOptions = {
      containerClassName: "hamrs-toast-container",
      filterBeforeCreate: (toast: typeof Toast) => {
        const settingStore = useSettingStore(); // Pinia is now active
        if (!settingStore.displayToasts) {
          return false;
        }
        return toast;
      },
    };

    app
      .use(formkitPlugin, defaultConfig(formkitConfig))
      .use(LDPlugin, { clientSideID: LD_CLIENT_SIDE_ID })
      .use(router)
      .use(FloatingVue)
      .use(autoAnimatePlugin)
      .use(Toast, toastOptions);

    // Initialize Sentry after app and router are available
    if (ENVIRONMENT != "development") {
      initializeSentry(app, router, APP_VERSION);
    }

    // Mount the app
    app.mount("#app");

    hamrsClientLogger("App initialized successfully.");

  } catch (error) {
    console.error('Application initialization failed:', error);
    // Handle the error appropriately
    // You might show an error message to the user or route to an error page
  }
}

bootstrap();