<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { storeToRefs } from 'pinia';
  import isElectron from 'is-electron';
  import LoadingPage from '@/pages/LoadingPage.vue';
  import ReloadPrompt from '@/components/ReloadPrompt.vue';
  import { useProfileStore } from '@/stores/ProfileStore';
  import { useSyncStore } from '@/stores/SyncStore';
  import { useSettingStore } from '@/stores/SettingStore';
  import { ensureIndexes } from "@/repos/PouchDB";

  import type { RouteLocationNormalized } from 'vue-router';
  import { router, NEW_PROFILE_PAGE_ROUTE_NAME, SYNC_PAGE_ROUTE_NAME } from '@/router/router';
  import { useNetworkStatus } from '@/composables/useNetworkStatus';
  import { hamrsClientLogger } from '@/utils/loggers';
  import { requireSync, hamrsDotAppURL, haveLoadingPage } from '@/config';
  import type { ProfileInterface } from '@/interfaces';

  const profileStore = useProfileStore();
  const settingStore = useSettingStore();
  const syncStore = useSyncStore();
  const { sync } = storeToRefs(syncStore);
  const { isOnline } = useNetworkStatus();

  const loading = ref(haveLoadingPage);

  hamrsClientLogger('Online:', isOnline.value);
  hamrsClientLogger('Require Sync:', requireSync);
  hamrsClientLogger('Is Electron:', isElectron());

  // Ensure that new users without profiles can only go to the NewProfilePage or
  // the SyncPage.
  function redirectToNewProfilePageIfFirstTime(to: RouteLocationNormalized) {
    if (profileStore.profiles.length === 0 &&
      to.name !== NEW_PROFILE_PAGE_ROUTE_NAME &&
      to.name !== SYNC_PAGE_ROUTE_NAME
    ) {
      return {
        name: NEW_PROFILE_PAGE_ROUTE_NAME,
        query: { firstTimeSetup: 'true' }
      };
    }
  }

  function setCurrentProfile(profile: ProfileInterface) {
    profileStore.activateProfile(profile);
  }

  async function initializeDatabase() {
    // Start syncing the database and look for a sync token.
    await syncStore.load();
    await ensureIndexes();
    // If this app is running in the mode that needs sync (the web client), and
    // they don't already have a sync token in place, then we need to get one. We
    // redirect them to the sign up page on hamrs.app and they'll come back here
    // with a token in a query param.
    if (requireSync && !sync.value.hamrs?.apiKey) {

      const token = new URL(window.location.toString()).searchParams.get('token');
      if (!token) {
        return { redirectToGetAccount: true };
      }

      await syncStore.create(token);
    }

    // We have to do an initial load of the profiles so we can handle redirection.
    await profileStore.fetchProfiles();

    // Ensure that there's always an active profile. If they've previously
    // configured one in their local settings then use that, otherwise just use
    // their first profile.
    if (profileStore.profiles.length > 0) {
      if (settingStore.activeProfileID) {
        const profile = profileStore.findOne(settingStore.activeProfileID);
        if (profile !== undefined) { setCurrentProfile(profile); }
      } else {
        setCurrentProfile(profileStore.profiles[0]);
      }
    }

    // After the profileStore is populated we can register a navigation guard to
    // keep new users on the setup pages. If we register this guard too soon then
    // existing users will always start on the first time setup experience.
    router.beforeEach(redirectToNewProfilePageIfFirstTime);

    // However, since we've waited this long to put the guard in place then we
    // have to invoke it once ourselves.
    return {
      routerRedirect: redirectToNewProfilePageIfFirstTime({
        // Add necessary properties here
      } as RouteLocationNormalized)
    };
  }

  function sleep(ms: number) {
    return new Promise<void>((resolve) => {
      setTimeout(() => { resolve(); }, ms);
    });
  }

  onMounted(async () => {
    // Start initializing the database and concurrently start a 2 second timer.
    // This lets us have a minimum time before we turn off the loading screen so
    // it's not a weird flash.
    Promise.all([
      initializeDatabase(),
      sleep(1000),
    ]).then(([{ redirectToGetAccount, routerRedirect }]) => {
      if (redirectToGetAccount) {
        window.location.href = `${hamrsDotAppURL}/users/sign_in?logger_return=true`;
        return;
      }

      if (routerRedirect) {
        router.push(routerRedirect);
      }
      loading.value = false;
    });
  });
</script>

<template>
  <LoadingPage v-if="loading" />
  <router-view v-if="!loading" />
  <ReloadPrompt />
</template>
