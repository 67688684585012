import { defineStore } from "pinia";
import type { LookupServiceProviderType } from "@/types";
import { useProfileStore } from "@/stores/ProfileStore";
import LookupService from "@/services/LookupService";
import { lookupsLogger } from "@/utils/loggers";
import { useSettingStore } from '@/stores/SettingStore';
import { useToastSystem } from '@/composables/useToastSystem';
import { useOnline } from '@vueuse/core'
import { ProviderSessionExpiredError } from "@/utils/errors/LookupServiceErrors";
export const useCallsignLookupsStore = defineStore("lookups", {
  state: () => ({
    sessionKey: "" as string,
    client: null as LookupService | null,
  }),

  actions: {
    setClient(provider: LookupServiceProviderType) {
      this.client = new LookupService(provider);
    },
    setSessionKey(sessionKey: string) {
      this.sessionKey = sessionKey;
    },
    async initProvider(provider: LookupServiceProviderType) {
      try {
        lookupsLogger('Initializing provider:', provider);
        const { username, password } = this.getCredentialForProvider(provider);
        this.setClient(provider);
        if (
          this.client &&
          this.client.requiresAuthentication &&
          username &&
          password
        ) {
          try {
            const sessionKey = await this.client.authenticate(username, password);
            lookupsLogger(`Initialized: ${provider}`);
            this.setSessionKey(sessionKey);
          } catch (error) {
            const toast = useToastSystem();
            toast.lookupProviderErrorToast(provider);
            console.error("Error during authentication:", error);
          }
        } else {
          lookupsLogger(`Initialized: ${provider}`);
        }
      } catch (initError) {
        console.error("Provider initialization error:", initError);
        throw initError; // Re-throw if you want the error propagated or handle it here
      }
    },
    async lookupCallsign(callsign: string) {
      const online = useOnline();
      if (!useSettingStore().doLookups) {
        lookupsLogger('Skipping callsign lookup as it is disabled in settings');
        throw new Error("Callsign lookups disabled in settings");
      }
      if (!online.value) {
        lookupsLogger('Skipping callsign lookup as the user is offline');
        throw new Error("Callsign lookups disabled because offline");
      }

      lookupsLogger('Looking up callsign:', callsign);
      if (!this.client) {
        throw new Error("Client not initialized");
      }

      try {
        return await this.client.lookupCallsign(callsign, this.sessionKey);
      } catch (error) {
        if (error instanceof ProviderSessionExpiredError) {
          lookupsLogger('Session expired, re-authenticating and retrying lookup');
          try {
            const { username, password } = this.getCredentialForProvider(this.client.providerName);
            if (username && password) {
              this.sessionKey = await this.client.authenticate(username, password);
              return await this.client.lookupCallsign(callsign, this.sessionKey);
            } else {
              throw new Error("Authentication credentials are missing");
            }
          } catch (authError) {
            console.error("Re-authentication failed:", authError);
            throw authError;
          }
        } else {
          console.error("Lookup failed:", error);
          throw error; // Re-throw other errors
        }
      }
    },

    getCredentialForProvider(provider: LookupServiceProviderType): {
      username?: string;
      password?: string;
    } {
      const profileStore = useProfileStore();
      const { activeProfile } = profileStore;

      const credentials: {
        username?: string;
        password?: string;
      } = { username: undefined, password: undefined };

      if (!activeProfile) {
        return credentials;
      }
      switch (provider) {
        case "QRZ":
          credentials.username = activeProfile.qrz.username;
          credentials.password = activeProfile.qrz.password;
          break;
        case "HAMQTH":
          credentials.username = activeProfile.hamQth.username;
          credentials.password = activeProfile.hamQth.password;
          break;

      }

      return credentials;
    },
  },
});
