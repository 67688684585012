import type { LookupServiceResponseType, QrzLookupResponseType } from "@/types";
export default function QrzResponseToLogbookResponseTransformer(
  response: QrzLookupResponseType
): LookupServiceResponseType {
  const lookup = response.QRZDatabase.Callsign;
  return {
    provider: "QRZ",
    callsign: lookup?.call ?? "",
    fullName: lookup?.name_fmt ?? `${lookup?.fname ?? ""} ${lookup?.name ?? ""}`,
    firstName: lookup?.fname ?? "",
    lastName: lookup?.name ?? "",
    address1: lookup?.addr1 ?? "",
    address2: "",
    city: lookup?.addr2 ?? "",
    state: lookup?.state ?? "",
    zip: lookup?.zip ?? "",
    country: lookup?.country,
    gridsquare: lookup?.grid,
    latitude: lookup?.lat,
    longitude: lookup?.lon,
    ituZone: lookup?.ituzone ?? undefined,
    cqZone: lookup?.cqzone ?? undefined,
    county: lookup?.county ?? "",
    class: lookup?.class,
    continent: undefined,
    email: lookup?.email || "",
  };
}
