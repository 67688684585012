// migrationRunner.ts
import { getMigrationVersion, setMigrationVersion } from '@/repos/PouchDB';
import migration1 from './1731167257789-populate-qso-count-on-logbooks';
import { pouchLogger } from '@/utils/loggers';

const migrations = [
  { version: 1731167257789, migrate: migration1 },
  // Add other migrations here
];

export async function runMigrations() {
  try {
    const currentVersion = await getMigrationVersion();
    pouchLogger(`Current migration version: ${currentVersion}`);
    // Filter and sort pending migrations
    const pendingMigrations = migrations
      .filter((migration) => migration.version > currentVersion)
      .sort((a, b) => a.version - b.version);
    pouchLogger(`Pending migrations: ${pendingMigrations.length}`);
    for (const migration of pendingMigrations) {
      try {
        // Execute the migration script
        await migration.migrate();
        // Update the migration version after successful execution
        await setMigrationVersion(migration.version);
        pouchLogger(`Migration ${migration.version} applied successfully.`);
      } catch (err) {
        pouchLogger(`Error applying migration ${migration.version}:`, err);
        throw err; // re-throw to halt further migrations
      }
    }
  } catch (err) {
    pouchLogger('Migration process failed:', err);
  }
}