import type {
  LookupServiceResponseType,
  HamDbResponseType,
  HamQthResponseType,
  LookupServiceProviderType,
  QrzLookupResponseType
} from "@/types";

import HamDbResponseToLogbookResponseTransformer from "@/transformers/HamDbResponseToLogbookResponseTransformer";
import HamQthResponseToLogbookResponseTransformer from "@/transformers/HamQthResponseToLogbookResponseTransformer";
import QrzResponseToLogbookResponseTransformer from "@/transformers/QrzResponseToLogbookResponseTransformer";

import { trimObjectValues } from "@/utils/formatters";

type TransformFunctionType = {
  [key in LookupServiceProviderType]: (response: any) => LookupServiceResponseType;
};

type ResponseType = HamDbResponseType | HamQthResponseType | QrzLookupResponseType

const transformFunctions: TransformFunctionType = {
  HAMDB: HamDbResponseToLogbookResponseTransformer,
  HAMQTH: HamQthResponseToLogbookResponseTransformer,
  QRZ: QrzResponseToLogbookResponseTransformer,
};

function safeTrim(value: any, defaultValue: string = ''): string {
  return typeof value === 'string' ? value.trim() : defaultValue;
}

function safeToUpperCase(value: any, defaultValue: string = ''): string {
  return typeof value === 'string' ? value.toUpperCase() : defaultValue;
}

function normalizeCommonFields(
  response: Partial<LookupServiceResponseType>
): LookupServiceResponseType {
  const trimmedResponse = trimObjectValues(response) as LookupServiceResponseType;

  return {
    ...trimmedResponse,
    callsign: safeToUpperCase(trimmedResponse.callsign),
    fullName: safeTrim(trimmedResponse.fullName),
    firstName: safeTrim(trimmedResponse.firstName),
    lastName: safeTrim(trimmedResponse.lastName),
    address1: safeToUpperCase(trimmedResponse.address1),
    address2: safeToUpperCase(trimmedResponse.address2),
    city: safeToUpperCase(trimmedResponse.city),
    country: safeToUpperCase(trimmedResponse.country),
    county: safeToUpperCase(trimmedResponse.county),
  };
}

export default function LookupServiceResponseTransformer(
  response: ResponseType,
  service: LookupServiceProviderType
): LookupServiceResponseType {
  const transformFunction = transformFunctions[service];

  if (!transformFunction) {
    throw new Error(`No transform function defined for service: ${service}`);
  }

  const transformResponse = transformFunction(response);
  return normalizeCommonFields(transformResponse);
}
