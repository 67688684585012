// SyncPouchRepo.ts
import PouchDB from "@/repos/PouchDB";
import type { SyncType } from "@/types";

const syncID = 'SYNC'

const update = async (attrs: Partial<SyncType>): Promise<SyncType> => {
  const updatedAt = new Date().toISOString();
  const newSync = {
    _id: syncID,
    updatedAt,
    ...attrs,
  };

  await PouchDB.upsert(syncID, () => newSync);
  return PouchDB.get(syncID);
};

const find = async (): Promise<SyncType> => {
  return PouchDB.get(syncID);
}

export const SyncPouchRepo = {
  update,
  find
};
