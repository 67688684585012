import type {
  HamDbResponseType,
  LookupServiceProviderType,
  LookupServiceResponseType
} from "@/types";
import { ProviderCallsignNotFoundError } from "@/utils/errors/LookupServiceErrors";
import type { LookupServiceInterface } from "@/interfaces/LookupServiceInterface";
import LookupServiceResponseTransformer from "@/transformers/LookupServiceResponseTransformer";
const HAM_DB_ENDPOINT = "https://api.hamdb.org/v1";

class HamDbService implements LookupServiceInterface {
  readonly providerName: LookupServiceProviderType = "HAMDB";
  readonly requiresAuthentication: boolean = false;
  sessionKey?: string
  constructor(sessionKey: string = "") {
    this.sessionKey = sessionKey;
  }

  public async lookupCallsign(
    callsign: string,
    _sessionKey?: string // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Promise<LookupServiceResponseType> {
    if (!callsign) {
      throw new Error("You must provide a callsign");
    }

    const response = await fetch(`${HAM_DB_ENDPOINT}/${callsign}`);

    if (!response.ok) {
      throw new Error(`[HAMDB] Error fetching data: ${response.status}`);
    }

    const data: HamDbResponseType = await response.json();

    if (data.hamdb.messages.status === "NOT_FOUND") {
      throw new ProviderCallsignNotFoundError(
        this.providerName,
        "Callsign not found"
      );
    }
    const normalizeResponse = this.normalizeResponse(data);
    return normalizeResponse;
  }
  private normalizeResponse(
    response: HamDbResponseType
  ): LookupServiceResponseType {
    return LookupServiceResponseTransformer(response, this.providerName);
  }
}

export default HamDbService;